import React from 'react'
import { Link } from 'gatsby'

import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/boy.jpg'

const ProgramLinks = props => (
  <section id="two" className="spotlights">
    <section>
      <Link to="/financial-aid" className="image">
        <img src={pic08} alt="" />
      </Link>
      <div className="content">
        <div className="inner">
          <header className="major">
            <h3>Financial Aid</h3>
          </header>
          <p>
            Financial aid is a mechanism that reduces out-of-pocket costs that
            the student and/or the student’s parents must pay to obtain a
            specific postsecondary education.
          </p>
          <ul className="actions">
            <li>
              <Link to="/financial-aid" className="button">
                Learn more
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section>
      <Link to="/school-disclosures" className="image">
        <img src={pic09} alt="" />
      </Link>
      <div className="content">
        <div className="inner">
          <header className="major">
            <h3>BPPE Disclosures</h3>
          </header>
          <p>Download our student disclosures.</p>
          <ul className="actions">
            <li>
              <Link to="/school-disclosures" className="button">
                Learn more
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
    {/*
    <section>
      <Link to="/employment" className="image">
        <img src={pic10} alt="" />
      </Link>
      <div className="content">
        <div className="inner">
          <header className="major">
            <h3>Gainful Employment</h3>
          </header>
          <p>Download our gainful employment information.</p>
          <ul className="actions">
            <li>
              <Link to={ '/gainful-employment/' + props.ge } className="button">
                Learn more
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
    */}
    <section>
      <Link to="/employment" className="image">
        <img src={pic10} alt="" />
      </Link>
      <div className="content">
        <div className="inner">
          <header className="major">
            <h3>How to Enroll</h3>
          </header>
          <p>Enroll in this program today!</p>
          <ul className="actions">
            <li>
              <Link to="/enrollment" className="button">
                Learn more
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </section>
)

export default ProgramLinks
