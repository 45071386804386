import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import ProgramLinks from '../components/ProgramLinks'

const Landing = props => (
  <Layout>
    <Helmet>
      <title>Thanh Le College, School of Cosmetology - Cosmetician</title>
      <meta name="description" content="Cosmetician" />
    </Helmet>

    <BannerLanding
      title="Cosmetician"
      subtitle="skin care"
      color="cosmetician"
    />

    <div id="main">
      <section id="one">
        <div className="inner">
          <p>Program Level - Graduate Certificate</p>
          <p>Program Length - 30 weeks</p>
          <header className="major">
            <h2>Performance Objectives</h2>
          </header>
          <ul>
            <li>
              Acquire knowledge of laws and rules regulating California’s
              cosmetology establishment practices.
            </li>
            <li>
              Acquire the knowledge of sanitation and sterilization as related
              to all functions of Cosmetician.
            </li>
            <li>
              Acquire the knowledge of subjects relative to Cosmetician
              including anatomy, physiology, chemistry and the theory relative
              to the practical procedures to be performed.
            </li>
          </ul>
          <header className="major">
            <h2>Skills to be Developed</h2>
          </header>
          <ul>
            <li>
              The proper use of implements relative to all Cosmetician services.
            </li>
            <li>
              The ability to properly analyze the skin to identify any
              conditions or disorders prior to performing services.
            </li>
            <li>
              Develop the knowledge to confidently select and administer
              products based on individual customer needs.
            </li>
            <li>
              Develop the knowledge and confidence to safely perform all
              operations authorized for and Cosmetician.
            </li>
          </ul>
          <header className="major">
            <h2>Attitudes and Appreciations to be Developed</h2>
          </header>
          <ul>
            <li>
              Learn to appreciate good workmanship common to practicing
              Cosmeticians.
            </li>
            <li>
              Develop a positive attitude toward the public and your fellow
              workers.
            </li>
            <li>Learn and Appreciate honesty and integrity.</li>
            <li>
              Learn to act professionally, especially when dealing with patrons
              and colleagues.
            </li>
          </ul>
          <header className="major">
            <h2>Career Opportunities for Cosmetologists and Job Outlook</h2>
          </header>
          <p>
            Thanh Le College, School of Cosmetology graduates have found careers
            as:
          </p>
          <ul>
            <li>Owners of a salon or spa</li>
            <li>Managers of a salon or spa</li>
            <li>Managers of a salon or spa</li>
            <li>Makeup artists</li>
            <li>Cosmetology Instructor</li>
            <li>And other interesting fields!</li>
          </ul>
        </div>
      </section>
      <ProgramLinks ge="cosmetician" />
    </div>
  </Layout>
)

export default Landing
